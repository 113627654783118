import React, { useState } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { USER_INFO } from "@/constanst/auth";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "@/components/common/Modal";

export default function LogIn() {
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleSubmit = () => {
    let loginUser = USER_INFO.filter((item) => item.password === password);
    let redirect;
    const announcement = searchParams.get("announcement");
    const type = searchParams.get("type");
    console.log(location);
    console.log(announcement, type);

    if (loginUser.length === 0) {
      openModal();
      setModalText("비밀번호를 확인해주세요.");
      return;
    } else {
      sessionStorage.setItem("role", JSON.stringify(loginUser));
    }

    if (
      loginUser[0].role === "user" &&
      location.state.announcement &&
      location.state.type
    ) {
      console.log(location.state.from);
      redirect = `/${location.state.from}`;
    } else {
      redirect = "/";
    }
    console.log(redirect);
    navigate(redirect);
  };
  return (
    <div className="w-full h-screen flexCenter max-sm:flex-col gap-[30px]">
      <img
        alt="logo"
        src="/big_logo.png"
        className="w-[320px]  max-sm:w-[150px] "
      />
      <div className="w-[380px] max-sm:w-[300px] max-sm:mb-[50px]">
        <div className="text-[30px] mb-[30px] max-sm:text-[20px] max-sm:mb-[5px]">
          정책 자금 지원 서비스
        </div>
        <div className="text-[14px] max-sm:text-[12px]">
          비밀번호를 입력해주세요
        </div>
        <div className="flex gap-2">
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            variant="outline"
            className="max-sm:text-[14px]"
            onClick={handleSubmit}
          >
            확인
          </Button>
        </div>
      </div>
      {isModalOpen && (
        <Modal isOpen={isModalOpen} contents={modalText} onClose={closeModal} />
      )}
    </div>
  );
}
