import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import PDFViewer from "./PDFViewer";
import { Button } from "@/components/ui/button";
import { Copy } from "lucide-react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useEffect, useState } from "react";
import { FILE_LIST } from "@/constanst/fileList";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { REACT_APP_SERVER } from "@/constanst/auth";
import Modal from "@/components/common/Modal";

export default function Main() {
  const [link, setLink] = useState("");
  const [selectValue, setSelectValue] = useState<string[] | null>(null);
  const [fileList, setFileList] = useState<string[] | null>(null);
  const [role, setRole] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalText, setModalText] = useState("");

  useEffect(() => {
    const announcement = searchParams.get("announcement");
    const type = searchParams.get("type");
    if (announcement && type) {
      setFileList([...FILE_LIST[announcement], ...FILE_LIST[type]]);
    }
    const user = sessionStorage.getItem("role");
    if (user) {
      setRole(JSON.parse(user)[0].role);
    }
  }, []);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const createLink = () => {
    if (!selectValue || selectValue?.length < 2) {
      openModal();
      setModalText("공고와 자금유형을 모두 선택 후 링크 생성해주세요");
      return;
      // return alert("공고와 자금유형을 모두 선택 후 링크 생성해주세요");
    }
    console.log(location);
    setLink(
      `${REACT_APP_SERVER}/?announcement=${selectValue[0]}&type=${selectValue[1]}`
    );
  };

  const handleFile = () => {
    if (!selectValue || selectValue?.length < 2) {
      openModal();
      setModalText("공고와 자금유형을 모두 선택해주세요.");
      return;
    }
    setFileList([...FILE_LIST[selectValue[0]], ...FILE_LIST[selectValue[1]]]);
  };

  const handleSelectBox = (fileIndex, typeIndex) => {
    let tmpArr: string[];
    if (selectValue) {
      tmpArr = [...selectValue];
    } else {
      tmpArr = [];
    }
    tmpArr[typeIndex] = fileIndex;
    setSelectValue(tmpArr);
  };
  return (
    <div className="max-sm:px-[0px] px-[100px] ">
      <div className="bg-white py-[40px]">
        <div className="flex w-full gap-[10px] pl-[80px] max-sm:pl-[20px] flex-wrap">
          {role === "admin" && (
            <>
              <div className="flex gap-[5px]">
                <Select onValueChange={(value) => handleSelectBox(value, 0)}>
                  <SelectTrigger className="w-[180px] max-sm:w-[150px]">
                    <SelectValue placeholder="공고문" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="0">
                      [2024년 3분기] 소상공인정택자금 대리대출
                    </SelectItem>
                  </SelectContent>
                </Select>
                <Select onValueChange={(value) => handleSelectBox(value, 1)}>
                  <SelectTrigger className="w-[180px] max-sm:w-[150px]">
                    <SelectValue placeholder="자금유형" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="0">
                      소공인특화자금(성장기반자금)
                    </SelectItem>
                    <SelectItem value="1" disabled>
                      일반경영안정자금(일반자금)
                    </SelectItem>
                    <SelectItem value="2" disabled>
                      재해 피해 소상공인(특별경영안정자금)
                    </SelectItem>
                    <SelectItem value="3" disabled>
                      일시적 경영 애로 소상공인(특별경영안정자금)
                    </SelectItem>
                    <SelectItem value="4" disabled>
                      장애인기업지원자금(특별경영안정자금)
                    </SelectItem>
                    <SelectItem value="5" disabled>
                      대환대출(특별경영안정자금)
                    </SelectItem>
                  </SelectContent>
                </Select>
                <Button variant="outline" onClick={handleFile}>
                  검색
                </Button>
              </div>
              <div className="flex gap-[10px]">
                <div className="flex h-10 w-[600px] rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background max-sm:w-[310px]">
                  <div className="grow">{link}</div>
                  <CopyToClipboard
                    text={link}
                    onCopy={() => {
                      if (!selectValue || link === "") {
                        openModal();
                        setModalText("링크 생성을 먼저 해주세요");
                      } else {
                        openModal();
                        setModalText("링크가 복사되었습니다");
                      }
                    }}
                  >
                    <Copy color="#a8a8a8ed" className="cursor-pointer" />
                  </CopyToClipboard>
                </div>
                <Button variant="outline" onClick={createLink}>
                  링크생성
                </Button>
              </div>
            </>
          )}
        </div>
        <div className="flex justify-center mt-[20px] gap-[16px] flex-wrap">
          <PDFViewer
            title="제안서"
            fileName={fileList ? fileList[0] : undefined}
          />
          <PDFViewer
            title="신청서"
            fileName={fileList ? fileList[1] : undefined}
          />
        </div>
      </div>
      {isModalOpen && (
        <Modal isOpen={isModalOpen} contents={modalText} onClose={closeModal} />
      )}
    </div>
  );
}
