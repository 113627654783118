import React, { useRef, useEffect } from "react";
import { Button } from "../ui/button";

interface ModalProps {
  isOpen: boolean;
  contents: string;
  onClose?: () => void;
}

const Modal: React.FC<ModalProps> = ({ isOpen, contents, onClose }) => {
  const modalRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    console.log(isOpen);
    if (isOpen) {
      modalRef.current?.showModal();
    } else {
      modalRef.current?.close();
    }
  }, [isOpen]);

  return (
    <dialog
      ref={modalRef}
      className="flex flex-col items-center justify-center p-10 rounded-lg shadow-xl bg-white max-w-md min-w-[300px] mx-auto backdrop:bg-opacity-60 backdrop:bg-black max-sm:w-[200px]"
    >
      <p className="text-gray-700 mb-4 text-[15px]">{contents}</p>
      <Button variant="outline" onClick={onClose}>
        확인
      </Button>
    </dialog>
  );
};

export default Modal;
