import LogIn from "@/view/LogIn";
import Main from "@/view/Main";

type RouterItem = {
  path: string;
  element: JSX.Element;
  withAuthorization: boolean;
};

export const RouterInfo: RouterItem[] = [
  { path: "/", element: <Main />, withAuthorization: true },
  { path: "/auth", element: <LogIn />, withAuthorization: false },
];
