import React, { useState } from "react";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import Loading from "@/components/common/Loading";
import { useMediaQuery } from "react-responsive";
import { REACT_APP_FILE_DOWNLOAD } from "@/constanst/auth";
import { Download } from "lucide-react";

pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.mjs";
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function PDFViewer({
  title,
  fileName,
}: {
  title: string;
  fileName?: string;
}) {
  const [numPages, setNumPages] = useState(0);
  const isSmall = useMediaQuery({
    query: "(max-width:576px)",
  });
  const isMedium = useMediaQuery({
    query: "(max-width:750px)",
  });

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div className="flex flex-col justify-center item-center py-[8px] bg-gray rounded-md ">
      {fileName && (
        <Document
          file={`${REACT_APP_FILE_DOWNLOAD}/${fileName}.pdf`}
          loading={<Loading />}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <div className="flex justify-between ml-[10px] mr-[20px] text-[16px] max-sm:text-[12px]">
            {title}
            <div>
              <a
                href={`/downloads/${fileName}.pdf`}
                download
                target="_blank"
                rel="noopener noreferrer"
              >
                <Download className="cursor-pointer" />
              </a>
            </div>
          </div>
          <div className="h-[calc(100vh-180px)] overflow-y-scroll max-sm:max-h-[600px)] max-sm:h-[500px]">
            {Array.from(new Array(numPages), (_, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                width={isSmall ? 350 : isMedium ? 600 : 750}
                className="relative border-[8px] border-gray rounded-xl max-sm:border-[3px]"
              />
            ))}
          </div>
        </Document>
      )}
    </div>
  );
}
