import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

interface AuthorizationProps {
  children: React.ReactNode;
}
//

const Authorization = ({ children }: AuthorizationProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const role: string | null = sessionStorage.getItem("role");
  const searchParams = new URLSearchParams(location.search);
  const announcement = searchParams.get("announcement");
  const type = searchParams.get("type");

  useEffect(() => {
    if ((!announcement || !type) && role) {
      if (JSON.parse(role)[0].role === "user") {
        navigate(-1);
      }
    }
  }, []);

  if (role) {
    return <>{children}</>;
  } else {
    return (
      <Navigate
        to={"/auth"}
        state={{
          from: `?announcement=${announcement}&type=${type}`,
          announcement,
          type,
        }}
      />
    );
  }
};

export default Authorization;
function useNavagation() {
  throw new Error("Function not implemented.");
}
