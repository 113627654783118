import { Skeleton } from "@/components/ui/skeleton";

export default function Loading() {
  return (
    <div className="flex flex-col space-y-3">
      <Skeleton className="h-[720px] w-[750px]  rounded-xl max-[576px]:w-[250px] max-[750px]:w-[600px]" />
      <div className="space-y-2">
        <Skeleton className="h-[30px] w-[750px]  rounded-xl max-[576px]:w-[250px] max-[750px]:w-[600px]" />
        <Skeleton className="h-[30px] w-[750px]  rounded-xl max-[576px]:w-[250px] max-[750px]:w-[600px]" />
      </div>
    </div>
  );
}
