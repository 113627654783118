import { Route, Routes } from "react-router-dom";
import LogIn from "./view/LogIn";
import Main from "./view/Main";
import AutoRouter from "./components/common/AutoRouter";

function App() {
  return (
    // <Routes>
    //   <Route path="/" element={<Main />} />
    //   <Route path="/auth" element={<LogIn />} />
    // </Routes>
    <AutoRouter />
  );
}

export default App;
