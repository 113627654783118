export const USER_INFO = [
  { role: "admin", password: "tlsgks!@qw", file1Index: -1, file2Index: -1 },
  { role: "user", password: "wkrma!@er", file1Index: 0, file2Index: 0 },
];

// export const REACT_APP_SERVER = "http://localhost:3000";

// export const REACT_APP_FILE_DOWNLOAD = "http://10.10.12.201:20002";

export const REACT_APP_SERVER = "http://financial-product.bigvalue.co.kr";

export const REACT_APP_FILE_DOWNLOAD =
  "http://financial-product.bigvalue.co.kr/downloads";
