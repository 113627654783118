import { Route, Router, Routes } from "react-router-dom";
import Authorization from "./Authorization";
import { RouterInfo } from "./RounterList";

const AutoRouter = () => {
  return (
    <Routes>
      {RouterInfo.map((route) => {
        return (
          <Route
            key={route.path}
            path={route.path}
            element={
              route.withAuthorization ? (
                <Authorization>{route.element}</Authorization>
              ) : (
                route.element
              )
            }
          />
        );
      })}
    </Routes>
  );
};

export default AutoRouter;
